import React from "react";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import { Link, graphql } from "gatsby";
import {
  navyBlue,
  mainRed,
  aku,
  innerWidth,
  transHover,
  gotham,
  gothamBold,
  screen,
} from "../components/common/variables";
import Arrow from "../images/svg/right-arrow.svg";
import logo from "../images/tenalphas-moving-content.jpg";
import { toSlug } from "../components/common/functions";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-bottom: 90px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    @media ${screen.small} {
      font-size: 2.7rem;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .link-list {
    margin-top: 30px;

    &__services,
    &__sectors {
      h2 {
        color: ${navyBlue};
        font-family: ${gothamBold};
        font-size: 1.4rem;
        margin: 18px 0;
        @media ${screen.small} {
          margin: 22px 0;
        }
      }

      li {
        list-style-type: disc;
        margin-left: 50px;
        margin-top: 10px;
        margin-bottom: 10px;
        color: ${mainRed};
        @media ${screen.small} {
          margin-top: 14px;
          margin-bottom: 14px;
        }

        .service-link,
        .sector-link {
          color: ${mainRed};
          font-family: ${gotham};
          display: block;
          font-size: 1.1rem;
        }
      }
    }

    &__link {
      color: ${mainRed};
      font-family: ${gotham};
      display: block;
      margin: 25px 0;
      font-size: 1.2rem;
      @media ${screen.small} {
        margin: 30px 0;
      }
    }
  }
`;

const SitemapPage = ({ data }) => {
  const services = data.services.edges;
  const sectors = data.sectors.edges;

  return (
    <Layout>
      <SEO title="TENALPHAS | Sitemap" image={logo} />
      <Wrapper>
        <h2 className="heading">
          <Arrow />
          SITEMAP
        </h2>
        <div className="link-list">
          <Link className="link-list__link" to="/">
            Home Page
          </Link>
          <Link className="link-list__link" to="/portfolio">
            Portfolio
          </Link>
          <ul className="link-list__services">
            <h2>Services</h2>
            {services.map(service => (
              <li key={service.node.id}>
                <Link
                  className="service-link"
                  to={`/${toSlug(service.node.data.title.text)}`}
                >
                  {service.node.data.title.text}
                </Link>
              </li>
            ))}
            <li>
              <Link className="service-link" to="/design">
                Design
              </Link>
            </li>
            <li>
              <Link className="service-link" to="/infographics">
                Infographics
              </Link>
            </li>
            <li>
              <Link className="service-link" to="/photography">
                Photography
              </Link>
            </li>
          </ul>
          <ul className="link-list__sectors">
            <h2>Sectors</h2>
            {sectors.map(sector => (
              <li key={sector.node.id}>
                <Link
                  className="service-link"
                  to={`/${toSlug(sector.node.data.title.text)}`}
                >
                  {sector.node.data.title.text}
                </Link>
              </li>
            ))}
          </ul>
          <Link className="link-list__link" to="/about">
            About Us
          </Link>
          <Link className="link-list__link" to="/testimonials">
            Testimonials
          </Link>
          <Link className="link-list__link" to="/awards">
            Awards
          </Link>
          <Link className="link-list__link" to="/accreditation">
            Accreditation
          </Link>
          <Link className="link-list__link" to="/wollongong">
            Wollongong
          </Link>
          <Link className="link-list__link" to="/news">
            News
          </Link>
          <Link className="link-list__link" to="/disclaimer-copyright-privacy">
            Disclaimer, Copyright & Privacy
          </Link>
          <Link className="link-list__link" to="/terms-conditions">
            Terms & Conditions
          </Link>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default SitemapPage;

export const dataQuery = graphql`
  {
    services: allPrismicService {
      edges {
        node {
          id
          data {
            title {
              text
            }
          }
        }
      }
    }
    sectors: allPrismicSector {
      edges {
        node {
          id
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;
